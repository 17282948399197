import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Header from '@components/header'
import { Mail } from 'react-feather'

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[ key ]))
    .join('&')
}

const ContactTemplate = ({ data: { prismicContact: { contact, lang } }, pageContext: { alternateLanguages } }) => {
  const [state, setState] = useState({})
  const [status, setStatus] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChange = e => {
    setState({ ...state, [ e.target.name ]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setStatus('loading')
    const form = e.target
    try {
      const recaptchaValue = await executeRecaptcha('contact_form')

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          'g-recaptcha-response': recaptchaValue,
          ...state
        })
      })
      setStatus('success')
      setTimeout(() => {
        setStatus('')
      }, 3000)
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  return (
    <Layout lang={lang} alternateLanguages={alternateLanguages}>
      <SEO title={contact.header_title.text} />
      <Header title={contact.header_title.text} image={contact.header_background_image} video={contact.header_background_video} />
      <div className="section has-background-secondary contact">
        <div className="container is-half-width">
          <form
            className="form"
            name="Contact Form"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="Contact Form" />
            <div className="field">
              <div className="control">
                <label className="has-text-primary" htmlFor="name">Your Name:
                  <input className="input" id="name" type="text" name="name" onChange={handleChange} required />
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="has-text-primary" htmlFor="email">Your Email:
                  <input className="input" id="email" type="email" name="email" onChange={handleChange} required />
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="has-text-primary" htmlFor="message">Message:
                  <textarea className="textarea" id="message" name="message" onChange={handleChange} required />
                </label>
              </div>
            </div>
            {status === 'error' && <p className="help is-danger has-text-right has-margin-bottom-10">It seems there was an error sending this message - please try again.</p>}
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button className={`button is-special-link ${status === 'loading' ? 'is-loading' : status === 'success' ? 'is-success' : 'is-primary'}`} type="submit">
                  <span>{status === 'success' ? 'Sent!' : 'Send'}</span>
                  <span className="icon">
                    <Mail />
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

ContactTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

const wrappedRecaptchaComponent = ({ data, pageContext }) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <ContactTemplate data={data} pageContext={pageContext} />
  </GoogleReCaptchaProvider>
)
wrappedRecaptchaComponent.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export default wrappedRecaptchaComponent

export const query = graphql`query ContactTemplate ($lang: String!) {
    prismicContact (lang: {eq: $lang}) {
      lang
      contact: data {
        header_title {
          text
        }
        header_background_image {
          alt
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1920,
                duotone: { highlight: "#000000", shadow: "#000000", opacity: 20 }
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header_background_video {
          url
        }
      }
    }
  }
`
